<template>
  <div class="admission-modal">
    <button class="btn btn-main" @click="openModal">Записаться</button>

    <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>

        <div class="container">
          <div class="row justify-content-center">
            <div class="col-10">
              <div class="title mb-4">Консультационная поддержка по вопросам приема в вуз</div>
            </div>
          </div>
          <div class="row justify-content-center mb-3">
            <div class="col-12 col-sm-4 col-lg-3 p-2">
              <input v-model="name" type="text" class="form-control p-3" placeholder="Как к вам обращаться?">
            </div>
            <div class="col-12 col-sm-4 col-lg-3 p-2">
              <input v-model="email" type="email" class="form-control p-3" id="colFormLabel" placeholder="Электронная почта">
            </div>
            <div class="col-12 col-sm-4 col-lg-3 p-2">
              <input v-model="phone" type="text" v-mask="'+7 (###) ###-##-##'" class="form-control p-3" id="colFormLabel" placeholder="Телефон">
            </div>
            <div class="col-12 col-sm-6 col-md-3 p-2">
              <button class="btn btn-second" @click="save">Записаться</button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="text-center mb-2"><strong>Поступаю после окончания:</strong></div>
              <div class="d-flex justify-content-around">
                <div class="form-check">
                  <input v-model="entrantLevel" class="form-check-input" type="radio" name="entrantLevelRadio" id="entrantLevel1" value="1">
                  <label class="form-check-label" for="entrantLevel1">Школа</label>
                </div>
                <div class="form-check mb-4">
                  <input v-model="entrantLevel" class="form-check-input" type="radio" name="entrantLevelRadio" id="entrantLevel2" value="2">
                  <label class="form-check-label" for="entrantLevel2">Колледж</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="text-center mb-2"><strong>Форма обучения:</strong></div>
              <div class="d-flex justify-content-around">
                <div class="form-check">
                  <input v-model="entrantForm" class="form-check-input" type="radio" name="entrantFormRadio" id="entrantForm1" value="1">
                  <label class="form-check-label" for="entrantForm1">Очная</label>
                </div>
                <div class="form-check mb-4">
                  <input v-model="entrantForm" class="form-check-input" type="radio" name="entrantFormRadio" id="entrantForm2" value="2">
                  <label class="form-check-label" for="entrantForm2">Заочная (очно-заочная)</label>
                </div>
              </div>
            </div>
            <div class="col-12 my-2 d-flex justify-content-center">
              <div class="form-check text-center">
                <input v-model="entrantNotDefined" class="form-check-input" type="checkbox" id="notSelect">
                <label class="form-check-label" for="notSelect">
                  Еще не определился с выбором специальности.
                </label>
              </div>
              <span v-if="selectDirections.length === 0"></span>
            </div>
          </div>
          <div class="row">
            <div v-if="selectDirections.length > 0" class="col">
              <div class="row py-2 my-2 hover-effect" v-for="d, index in selectDirections" :key="d.profileId" @click="d.select = !d.select">
                <div class="col-1 number">
                  {{ ++index }}.
                </div>
                <div class="col-11">
                  <div class="direction-block">
                    <div class="direction">
                      <span class="code">{{ d.directionCode }}</span>
                      <span class="name">{{ d.directionName }}</span>
                    </div>
                    <div class="profile">
                      <span class="code">{{ d.profileCode }}</span>
                      <span class="name">{{ d.profileName }}</span>
                    </div>
                    <div class="label"> {{ d.levelName }} {{ d.branchName }} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectDirections.length < 5 && !entrantNotDefined" class="row my-2">
            <div class="col-12 mb-3">
              <span>Для выбора доступно {{ 5 - selectDirections.length }} из 5 специальностей: </span>
            </div>
            <div class="col">
              <input v-model="search" type="text" class="form-control" id="inlineFormInputName" placeholder="Поиск">
            </div>
            <div class="col">
              <select v-model="branch" class="form-control">
                <option selected value="0">Все филиалы</option>
                <option value="1">Ростовский Государственный Экономический Университет (РИНХ)</option>
                <option value="2">Таганрогский институт имени А.П.Чехова (РИНХ)</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="selectDirections.length < 5 && !entrantNotDefined" class="container container-org-direction">
          <div class="row py-2 my-2 bottom-line" v-for="d in directions" :key="d.profileId">
            <div class="col-12">
              <div class="direction-block hover-effect" @click="d.select = !d.select">
                <div class="direction">
                  <span class="code">{{ d.directionCode }}</span>
                  <span class="name">{{ d.directionName }}</span>
                </div>
                <div class="profile">
                  <span class="code">{{ d.profileCode }}</span>
                  <span class="name">{{ d.profileName }}</span>
                </div>
                <div class="label">{{ d.levelName }} {{ d.branchName }} </div>

              </div>
            </div>
          </div>
        </div>
      <div class="close-btn" @click="closeModal"><i class="fas fa-times"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'

  export default {
    data() {
      return {
        name: '',
        email: '',
        phone: '',
        search: '',
        entrantLevel: null,
        entrantForm: null,
        entrantNotDefined: null,
        isModalOpen: false,
        branch: '0',
        orgDirections: []
      }
    },
    mounted () {
      axios.get('/admission/org-direction').then((response) => {
        this.orgDirections = response.data
      })
    },
    methods: {
      openModal() {
        this.isModalOpen = true
      },
      closeModal() {
        this.isModalOpen = false
      },
      save() {
        console.log(this.phone.length)

        if (this.name === '' || this.email === '' || this.phone === '' || this.phone.length !== 18) {
          Swal.fire({
            title: 'Внимание!',
            text: 'Все поля обязательны к заполнению.',
            icon: 'warning'
          })
          return
        }
        const data = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          level: this.entrantLevel,
          form: this.entrantForm,
          selected: this.selectDirections.map(e => ({
            orgDirection: e.directionId,
            profile: e.profileId
          })
          )
        }
        Swal.fire({
          title: 'Подтверждение',
          text: 'Записаться на консультационную поддержку?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет'
        }).then((result) => {
          if (result.isConfirmed) {
            axios.post('/admission/select/', data).then(response => {
              Swal.fire({
                title: 'Успешно!',
                text: 'Вы записались на консультационную поддержку',
                icon: 'success'
              })
              this.closeModal()
            }).catch(error => {
              Swal.fire({
                title: 'Ошибка!',
                text: JSON.stringify(error.response.data).replace(/[\\[\]{}"]/g, ''),
                icon: 'error'
              })
            })
          }
        })
      }
    },
    watch: {
      entrantNotDefined(value) {
        if (value) {
          this.orgDirections.forEach(e => {
            e.select = false
          })
        }
      }
    },
    computed: {
      selectDirections() {
        return this.orgDirections.filter(item => item.select)
      },
      directions() {
        const excludeDirection = this.orgDirections.filter(item => item.select).map(e => e.directionId)
        console.log(excludeDirection)
        const searchTerm = this.search.toLowerCase()
        if (this.branch !== '0') {
          const branch = parseInt(this.branch)
          return this.orgDirections.filter(item =>
            !item.select && item.branchId === branch && !excludeDirection.includes(item.directionId) && (
              item.directionCode.toLowerCase().includes(searchTerm) ||
              item.directionName.toLowerCase().includes(searchTerm) ||
              item.profileCode.toLowerCase().includes(searchTerm) ||
              item.profileName.toLowerCase().includes(searchTerm)
            )
          )
        }
        return this.orgDirections.filter(item =>
          !item.select && !excludeDirection.includes(item.directionId) && (
            item.directionCode.toLowerCase().includes(searchTerm) ||
            item.directionName.toLowerCase().includes(searchTerm) ||
            item.profileCode.toLowerCase().includes(searchTerm) ||
            item.profileName.toLowerCase().includes(searchTerm)
          )
        )
      }
    }
  }
</script>

<style scoped>
.container-org-direction {
}
.admission-modal {
  position: relative;
  color: black;
  z-index: 999;
}
.admission-modal .number {
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
}
.hover-effect {
  transition: .7s;
  cursor: pointer;
}
.hover-effect:hover {
  cursor: pointer;
  background-color: #6f57f817;
}
.admission-modal .close-btn {
  position: absolute;
  right: 0;
  top: -.7rem;
  font-size: 2.5rem;
  color:red;
  cursor: pointer;
  padding: 1rem;
}
.admission-modal .bottom-line {
  border-bottom: 1px solid #513dc05e;
}
.admission-modal .label {
  color: black;
  font-size: 12px;
}
.direction-block {
  position: relative;
}
.direction-block .code {
  color: black;
  margin-right: .6rem;
}
.direction-block .label {
  color: #6f57f8;
  position: absolute;
  top: -13px;
  font-size: 11px;
}
.direction-block .name {
  color: black;
}
.admission-modal .title {
  text-align: center;
  color: #513dc0;
  font-weight: bold;
  font-size: 1.4rem;
}
.admission-modal .btn-main {
  font-size: 1.4rem;
  padding: 1.2rem 1.5rem;
  transition: .7s;
}
.admission-modal .btn-second {
  width: 100%;
  font-size: 1.4rem;
  height: 100%;
}
.admission-modal .btn {
  transition: .7s;
  background-color: #513dc0;
  color: white;
  font-weight: bold;
}
.admission-modal .btn:hover {
  background-color: #7c68f1;
  color: white;
}
.modal-overlay {
  padding: 30px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: top;
  z-index: 98;
}
.modal-content {
  /* height: 90%; */
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 99;
  /* margin: 0 30px; */
  overflow-y: scroll;
}
</style>
